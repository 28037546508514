import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import SpendAnalyticsRestData from '../Data/SpendAnalyticsRestData'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppSpendAnalyticsRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .SpendAnalyticsSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .HeadingEamilSpendAnalyticsOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    margin: auto;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .HeadingEamilSpendAnalytics {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaSpendAnalyticsStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .SubParaPonits {
    color: #047cc2;
    padding: 10px 0px 10px 0px;
    font-weight: bold;
    font-size: 21px;
  }
`

export const AppSpendAnalyticsRest = () => {
  return (
    <AppSpendAnalyticsRestWapper>
      <div>
        {SpendAnalyticsRestData.map((data, index) => {
          return (
            <div className='MainEmailProcurementSection' key={index}>
              <div className='SpendAnalyticsSectionEmail'>
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle='HeadingEamilSpendAnalyticsOne' />
                <ReUsePtag para={data.ParaOne} paraStyle='LeadParaSpendAnalyticsStyle' />
                <ReUsePtag para={data.ParaTwo} paraStyle='LeadParaSpendAnalyticsStyle' />
                <ReUsePtag
                  Icon={data.subHeadingOneParaTwo}
                  para={data.subParaOneParaTwo}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='SubParaPonits'
                />
                <ReUsePtag
                  Icon={data.subHeadingTwoParaTwo}
                  para={data.subParaTwoParaTwo}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='SubParaPonits'
                />
                <ReUsePtag
                  Icon={data.subHeadingThreeParaTwo}
                  para={data.subParaThreeParaTwo}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='SubParaPonits'
                />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='HeadingEamilSpendAnalytics' />
                <ReUsePtag para={data.ParaThree} paraStyle='LeadParaSpendAnalyticsStyle' />
                <ReUsePtag para={data.ParaFour} paraStyle='LeadParaSpendAnalyticsStyle' />
                {/* <ReUsePtag para={data.ParaFive} paraStyle='LeadParaSpendAnalyticsStyle' /> */}
                {/* <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaFiveSubPonitOne}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaFiveSubPonitTwo}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaFiveSubPonitThree}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaFiveSubPonitFour}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaFiveSubPonitFive}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                /> */}
                <ReUsePtag para={data.ParaSix} paraStyle='LeadParaSpendAnalyticsStyle' />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle='HeadingEamilSpendAnalytics' />
                <ReUsePtag para={data.ParaSeven} paraStyle='LeadParaSpendAnalyticsStyle' />
                <ReUsePtag para={data.ParaEight} paraStyle='LeadParaSpendAnalyticsStyle' />
                <ReUsePtag para={data.ParaNine} paraStyle='LeadParaSpendAnalyticsStyle' />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaNineSubPonitOne}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaNineSubPonitTwo}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaNineSubPonitThree}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaNineSubPonitFour}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaNineSubPonitFive}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaNineSubPonitSix}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.Icon}
                  para={data.ParaNineSubPonitSeven}
                  paraStyle='LeadParaSpendAnalyticsStyle'
                  IconStyle='iconColor'
                />
              </div>
            </div>
          )
        })}
      </div>
    </AppSpendAnalyticsRestWapper>
  )
}
