import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Icon: <FaArrowCircleRight />,
    HeadingOne: 'Introduction to Spend Analytics',
    ParaOne:
      'When we talk about Spend Analytics, what does it mean? As a broad brushstroke, Spend Analytics is the capture, configuration, understanding, and application of relevant data points as generated from data residing in various source systems, such as ERP systems, eProcurement software, travel and expense systems, P-cards, and other data systems, all which exist to manage complex data sets. What information each user can extract and utilize for their benefit is what is considered “Spend Analytics”.',
    ParaTwo: 'Three core areas of effective spend analytics are:',
    subHeadingOneParaTwo: 'Spend Visibility ⟶ ',
    subParaOneParaTwo:
      'to provide a detailed and comprehensive view of how money is moving through the organization to the external supply base.',
    subHeadingTwoParaTwo: 'Spend Analysis ⟶ ',
    subParaTwoParaTwo:
      'A process for analyzing historical corporate external spend data of an organization specifically used to provide spend visibility, understanding, and ultimately compliance and control.',
    subHeadingThreeParaTwo: 'Procurement Process Improvement ⟶ ',
    subParaThreeParaTwo:
      'Applying the spend analysis output and implementing changes based on specific desired conclusions to drive future performance with the focus on aligning and meeting corporate financial and other strategic goals.',
    HeadingTwo: 'Importance of Quality Spend Analytics',
    ParaThree:
      'Data integrity is the central pillar of creating holistically valuable outcomes. In most cases your data is derived or provided from multiple source systems, so ensuring your data is correct is the first step of the process. Integration to these various data sources helps to create a multidimensional view of internal and external spend. Once the data sets are determined correctly, the respective user is on their way to understanding how money is flowing in and out of their company. This approach generates the baseline of quality Spend Visibility.',
    ParaFour:
      'Once you have a unified view/visibility on the spend, the next critical process is to analyze the spend-related data (Spend Analysis). Spend analysis provides organizations with the flexibility to collect, process, and analyze procurement data. Businesses can use this information to manage their suppliers, build strategic sourcing strategies, track procurement performance, and realize cost savings, ultimately designed to improve operational efficiencies.',
    ParaSix:
      'In today’s era of digitization, just getting the data or analytics from spend management solutions is not enough. Ensuring clean and complete data is used as the starting point, and managing this between various technology systems (such as ERP, CMS, HR systems, etc.) is critical. After all, good and complete data results in good and complete outcomes. The amount of data in play varies from company to company and the current systems in use but the key is how to retrieve the accurate data in real-time from multiple sources and provide a unified and normalized view with actionable insights.',
    HeadingThree: 'Selecting the Optimal Spend Management Solution',
    ParaSeven:
      'The term “single source of truth” is commonly used to define a single system and single location to secure all of the data required for a necessary task. Small to medium-sized companies are best suited to consume single systems. Large enterprises are also seeing the value and flexibility provided in single quality systems of record, and are making the shift towards easy-to-consume and readily available data systems with the specific result being to make informed decisions with trusted data, once again, used to gain actionable insights. Ultimately, every company requires accurate, normalized, and easily accessible data. As the saying goes, “good data in, good information out”.',
    ParaEight:
      'Raindrop’s modern Enterprise Spend Management solution is powered by artificial intelligence (AI) and machine learning (ML), providing visibility and unparalleled capabilities in controlling how and who spends money at your company. Raindrop is specifically designed by Procurement professionals, and digitizes your Procurement processes, and created to focus on delivering efficiencies, be they cost savings, cost avoidance, or other programmatic benefits. Raindrop seamlessly and readily integrates with all major ERP systems, HR systems, and other central data sources to create a unified and normalized view of company spend. Raindrop’s easy-to-use interface enables users to address data as desired, further enabling you to create and share reports with several layers of analytics that are very easily accessible.',
    ParaNine: 'Here are some key features with Raindrop Advanced Spend Analytics:',

    ParaNineSubPonitOne:
      'Provides in-depth insights on analytics through visualization. From charts, graphs, tables, etc. in whichever format you desire, all immediately accessible with one single click.',
    ParaNineSubPonitTwo: 'Dynamic analytics capability to create customized reports from the data set.',
    ParaNineSubPonitThree:
      'Provides accurate and precise visibility on purchasing funds being spent, such as who is spending it and which suppliers are receiving the financial commitments.',
    ParaNineSubPonitFour:
      'Provides analytics with calls to action on areas of improvement or risk, opportunities, etc.',
    ParaNineSubPonitFive:
      'Encapsulates multiple types of key analytics into one combined or separate report like consumption analytics, cost benchmarking analytics, spend analytics, bid analytics, contract analytics, supplier risk, and performance analytics, and many more.',
    ParaNineSubPonitSix:
      'Provides business intelligence in areas like commodity management, category management, supplier consolidation or expansion, cost containment, etc.',
    ParaNineSubPonitSeven:
      'Provides a holistic view across the supply chain.  Spend and cost information can be combined with external data, such as geographical assessments, risk analyses, alternative supply sources, and financial statistics.  This type of 360-degree view enables companies to make better decisions, increasing the resiliency of their supply chain — a critical responsibility of the Procurement function.',
  },
]
